import React from 'react';
import "./Navbar.css";
import { ReactComponent as HackClubLogo } from '../../images/hackClubLogo.svg';
import { Button } from 'theme-ui';
import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <div className="navbar sticky">
            <HackClubLogo />
            <div className="nav-comp">
                <Link to='/'>
                    <span> Home </span>
                </Link>
                <Link to='/comingSoon'>
                    <span> Events </span>
                </Link>
                <Link to='/hackspace'>
                    <span> HackSpace </span>
                </Link>
                <Link to='/team'>
                    <span> Team </span>
                </Link>
                <Link to='/press'>
                    <span> Press </span>
                </Link>
            </div>
            <Link to='/membership'>
                <Button className="navbar__memberBtn" variant="cta" >BECOME A MEMBER</Button>
            </Link>
        </div>
    )
}

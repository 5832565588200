import { useState, useEffect, React } from "react";
import HackSpaceCard from "../../components/HackSpaceCard/HackSpaceCard";
import './HackSpace.css';
import hackspaceimg from "../../images/hackspaceimg.png";
import { Grid } from '@theme-ui/components';
export default function HackSpace(){
const [ hackXp, setHackXp ] = useState([]);
const [ hackTech, setHackTech ] = useState([]);
const [ hackKnowledge, setHackKnowledge ] = useState([]);
const [ hackParley, setHackParley ] = useState([]);
const hackXpUrl = 'https://api.airtable.com/v0/appmtTWeJeymk33xL/xp?maxRecords=2&view=Grid+view';
const hackTechUrl = "https://api.airtable.com/v0/appmtTWeJeymk33xL/tech?view=Grid+view";
const hackKnowledgeUrl = 'https://api.airtable.com/v0/appmtTWeJeymk33xL/know?maxRecords=2&view=Grid+view';
const hackParleyUrl = 'https://api.airtable.com/v0/appmtTWeJeymk33xL/parley?maxRecords=2&view=Grid+view';
/** @jsxImportSource theme-ui */

useEffect(() => {
    // HACK XP
    // fetch(hackXpUrl, { 
    //     method: 'GET',
    //     headers: {
    //         'Authorization': 'Bearer keyz4VQaC9u6Qt1iN'
    //     },
    // })
    // .then((response)=>{
    //     response.json();
    // })
    // .then((data)=>{
    //     console.log(data);
    //     setHackXp(data.records);
    //     return;
    // })
    // .catch((err)=>{
    //     console.log(err);
    // })

    //HACK TECH
    fetch(hackTechUrl, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer keyz4VQaC9u6Qt1iN'
        },
    })
    .then((response)=>{
        return response.json();
    })
    .then((data)=>{
        setHackTech(data.records);
    })
    .catch((err)=>{
        console.log(err);
    })

    //HACK KNOWLEDGE
    fetch(hackKnowledgeUrl, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer keyz4VQaC9u6Qt1iN'
        },
    })
    .then((response)=>{
        return response.json();
    })
    .then((data)=>{
        setHackKnowledge(data.records);
        return;
    })
    .catch((err)=>{
        console.log(err);
    })

    // HACK PARLEY
    fetch(hackParleyUrl, {
        method: 'GET',
        headers: {
            'Authorization': process.env.REACT_APP_AIRTABLE_API_KEY
        },
    })
    .then((response)=>{
        return response.json();
    })
    .then((data)=>{
        setHackParley(data.records);
        return;
    })
    .catch((err)=>{
        console.log(err);
    })
},[]);
var tempText = "";
    return (
        <div>
            <div className={'upper-container'}>
                <img src={hackspaceimg} alt="hackspaceimg" className={'hackspaceimg'}></img>
                <h1 className={'heading'}>HackSpace</h1>
            </div>
            <div className="team__content" sx={{ px: 5, py: 5 }}>
                {/* <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'primary' }}>Hack XP</span>
                <Grid columns={[1, 1, 2]}>
                    {hackXp.map((hxp)=>{
                        return(
                            <HackSpaceCard/>
                        );
                    })}
                </Grid>
                <a href=""><h3 sx={{ fontSize: 2, color: 'primary', mt: 5, textAlign: 'right', mt: 0 }}>View More ⇒</h3></a> */}
                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'orange', mt: 5, display: 'block' }}>Hack Tech</span>
                <Grid columns={[1, 1, 2]}>
                    {hackTech.map((htp)=>{
                            return(
                                <HackSpaceCard 
                                    key = {htp.id}
                                    title = {htp.fields.title}
                                    date = {htp.fields.date}
                                    image = {htp.fields.image}
                                    about = {htp.fields.desc}
                                    youtube = {htp.fields.youtube}
                                    spotify = {htp.fields.spotify}
                                    google = {htp.fields.google}
                                />
                            )
                    })}
                </Grid>
                <a href=""><h3 sx={{ fontSize: 2, color: 'primary', mt: 5, textAlign: 'right', mt: 0 }}>View More ⇒</h3></a>
                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'green', mt: 5, display: 'block' }}>Hack Knowledge</span>
                <Grid columns={[1, 1, 2]}>
                    {hackKnowledge.map((hkp)=>{
                            return(
                                <HackSpaceCard
                                    key = {hkp.id}
                                    title = {hkp.fields.title}
                                    date = {hkp.fields.date}
                                    about = {hkp.fields.desc}
                                    image = {hkp.fields.image}
                                    youtube = {hkp.fields.youtube}
                                    spotify = {hkp.fields.spotify}
                                    google = {hkp.fields.google}
                                />
                            );
                    })}
                </Grid>
                <a href=""><h3 sx={{ fontSize: 2, color: 'primary', mt: 5, textAlign: 'right', mt: 0 }}>View More ⇒</h3></a>
                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'purple', mt: 5, display: 'block' }}>Hack Parley</span>
                <Grid columns={[1, 1, 2]}>
                    {hackParley.map(hpp=>{
                        return(
                            <HackSpaceCard
                                key = {hpp.id}
                                title = {hpp.fields.title}
                                date = {hpp.fields.date}
                                image = {hpp.fields.image}
                                about = {hpp.fields.desc}
                                youtube = {hpp.fields.youtube}
                                spotify = {hpp.fields.spotify}
                                google = {hpp.fields.google}
                            />
                        )
                    })}
                </Grid>
                <a href=""><h3 sx={{ fontSize: 2, color: 'primary', mt: 5, textAlign: 'right', mt: 0 }}>View More ⇒</h3></a>
            </div>
        </div>
    )
}
import React from 'react';
import '../Philosophy/Philosophy.css';
import { Button } from 'theme-ui';

/** @jsxImportSource theme-ui */

export default function Philosophy() {
    return(
        <div className="philosophy" sx={{color: 'white'}}>
            <h1 style={{opacity: 0.75}}>~PHILOSOPHY~</h1>
            <h2>By The Students, For The Students.</h2>

            <p>Learning to code is uniquely like gaining a superpower—turning you from a consumer of technology into a creator. So it shouldn’t be taught like a class—it should be a creative, inclusive space. To foster this environment, KIIT Hack Club is student-led &amp; members make self-directed projects.</p>

            <a href='https://hackclub.com/philosophy/' target='_blank' rel="noreferrer">
                <Button>OUR PHILOSOPHY ➔</Button>
            </a>
        </div>
    )
}
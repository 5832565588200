import React from 'react'
import { Box, Button, Grid, Image, Text, Container } from '@theme-ui/components';

import '../Press/Press.css'

import pressbanner from '../../images/press-banner.png'

import hackclub from '../../logos/flag-standalone 2.svg'
import github from '../../logos/Asset 1 1.svg'
import replit from '../../logos/replit-logo-vector 1.svg'
import figma from '../../logos/Figma logo.svg'
import notion from '../../logos/notion-wordmark 1.svg'
import stickermule from '../../logos/stickermule.svg'

import gallerypic from '../../images/Rectangle 76.png'
import gallerypic6 from '../../images/gp6.png'
import gallerypic2 from '../../images/gp2.png'
import gallerypic3 from '../../images/gp3.png'
import gallerypic4 from '../../images/gp4.png'
import gallerypic5 from '../../images/gp5.png'


export default function Press() {
    return (
        <div className="press">
            <div className="press-top-banner">
                <img src={pressbanner} className="press-img" />
                <Text className="press-banner-text" sx={{ fontWeight: 'bold', color: 'white', fontSize: 7 }} >Press</Text>
            </div>

            <Container className="press-container">
                <Text sx={{ fontWeight: 'bold', color: '#EC3750', fontSize: 5 }} className="press-headers">About</Text><br />
                <Text sx={{ fontSize: 3 }}>
                    KIIT Hack Club is a tech community in Kalinga Institute of Industrial Technology, run by the students, for the students. It was founded in 2021 by Soham Malakar and Swastik Malakar. <br /><br />

                    If you are writing a story or have other inquires, please contact KIIT Hack Club: <Text sx={{ color: '#EC3750' }} ><a href="mailto:kiithackclub@gmail.com"> kiithackclub@gmail.com</a></Text>
                </Text><br />

                <Text sx={{ fontWeight: 'bold', color: '#5BC0DE', fontSize: 5 }} className="press-headers">Gallery</Text><br />

                <div className="row">
                    <div className="col"><img className="gallery-pic" src={gallerypic} /></div>
                    <div className="col"><img className="gallery-pic" src={gallerypic6} /></div>
                    <div className="col"><img className="gallery-pic" src={gallerypic2} /></div>
                </div>

                <div className="row">
                    <div className="col"><img className="gallery-pic" src={gallerypic3} /></div>
                    <div className="col"><img className="gallery-pic" src={gallerypic4} /></div>
                    <div className="col"><img className="gallery-pic" src={gallerypic5} /></div>
                </div>


                <Text sx={{ fontWeight: 'bold', color: '#FF8C37', fontSize: 5 }} className="press-headers">Presence</Text><br />

                <div className="press-links">
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://youtube.com/channel/UC5lpOLZYTRrctY8yjJXSwIg'>Youtube</a></Text>
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://twitter.com/kiithackclub/'>Twitter</a></Text>
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://instagram.com/kiithackclub/'>Instagram</a></Text>
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://github.com/kiithackclub'>Github</a></Text>
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://open.spotify.com/user/vr1yta2pvpk2g3d9wcl9irb6v'>Spotify</a></Text>
                    <Text sx={{ fontSize: 3, color: '#EC3750' }}><a target="_blank" rel="noreferrer" href='https://linkedin.com/company/kiithackclub/'>Linkedin</a></Text>
                </div>

                <Text sx={{ fontWeight: 'bold', color: '#33D6A6', fontSize: 5 }} className="press-headers">Supported By</Text><br />

                <div className="row">
                    <div className="col"><a href="https://hack.af/" target="__blank"><img className="press-logos" src={hackclub} /></a></div>
                    <div className="col"><a href="https://github.com/" target="__blank"><img className="press-logos" src={github} /></a></div>
                    <div className="col"><a href="https://repl.it/" target="__blank"><img className="press-logos" src={replit} /></a></div>
                </div>

                <div className="row">
                    <div className="col"><a href="https://www.figma.com/"  target="__blank"><img className="press-logos" src={figma} /></a></div>
                    <div className="col"><a href="https://www.stickermule.com/"  target="__blank"><img className="press-logos" src={stickermule} /></a></div>
                    <div className="col"><a href="https://www.notion.so/" target="__blank"><img className="press-logos" src={notion} /></a></div>
                </div>

            </Container>
        </div>
    )
}

import React from 'react';
import './HackSpaceCard.css';

import { ReactComponent as YoutubeI8 } from '../../images/icons8-youtube.svg';
import { ReactComponent as SpotifyI8 } from '../../images/icons8-spotify.svg';
import { ReactComponent as GooglePodcastI8 } from '../../images/icons8-google-podcasts.svg';
import { Card, Image, Text } from '@theme-ui/components';


/** @jsxImportSource theme-ui */
export default function HackSpaceCard(props) {
    // console.log(props);
    return (
        <Card className="HackSpaceCard" variant="compact" sx={{ maxWidth: 750, maxHeight: 300, backgroundColor:'white',p:0,my:'2rem'}}>
            <div className="HackSpaceCard__left">
                <Image decoding src={"https://cdn.jsdelivr.net/gh/kiithackclub/cdn@latest/"+props.image} alt="HackSpace" /> {/* src={props.img} */}
            </div>

            <div className="HackSpaceCard__right" sx={{ card: 'primary'}}>
            <div className="HackSpaceCard__right__top">
                <Text sx={{ fontSize: 4, fontWeight: 'bold' , lineHeight: 1.2}}>{props.title}</Text> {/* {props.heading} */}
                <div className="HackSpaceCard__social">
                        <a href={props.youtube} target="_blank" rel="noopener noreferrer"><YoutubeI8/></a>
                        <a href={props.spotify} target="_blank" rel="noopener noreferrer"><SpotifyI8/></a>
                        <a href={props.google} target="_blank" rel="noopener noreferrer"><GooglePodcastI8/></a>

                </div>
            </div>
            <div className="HackSpaceCard__content">
                <Text sx={{ fontSize: 3, fontWeight: 'bold', color: '#24B5A5', lineHeight: 1,paddingBottom:'10px'}}>{props.date}</Text> {/* {props.date} */}
                <Text sx={{ fontSize: 2, lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: props.about }}></Text> {/* dangerouslySetInnerHTML={{ __html: props.about }} */}
            </div>
            </div>
        </Card>
    )
}
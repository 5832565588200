import {useEffect, React} from 'react';
import './MemberCore.css';

import MembershipImg from "../../images/membership.png";
import { Box, Button, Grid, Image, Text } from '@theme-ui/components';

export default function MemberCore() {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (
        <div className='memberCore'>
            <div className='membercore_container'>
                <Text className='membercore_container_text' sx={{ fontWeight: 'bold', color: 'white'}} >Membership</Text>
                <Image src={MembershipImg} alt="Membership Img" />
            </div>

            <Grid sx={{ mt: 4, mb: 4 }} gap={0} columns={[1, null, 2]}>
                <Box sx={{height: 'narrow', px: '10%', py:  '15%', textAlign: 'center' }} bg="green">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 5}} >
                        <div>Become a KIIT Hack Club Member</div>
                    </Text><br/>
                    
                    <a target="_blank" rel="noreferrer" href="https://airtable.com/shrxQEaTNtWOvZGoX"><Button variant="cta" sx={{fontSize: 3}}>JOIN US</Button></a>
                </Box>
                <Box sx={{ height: 'narrow', px: '15%', py:  '15%', textAlign: 'center'}} bg="purple">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 5}} >
                        <div>Become a Core Team Member</div>
                    </Text> <br/>
                    
                    <a target="_blank" rel="noreferrer" href="https://airtable.com/shraD5PYiMLr2HEww"><Button variant="cta" sx={{fontSize: 3}}>JOIN US</Button></a>
                </Box>
            </Grid>
        </div>
    )
}

import { Grid } from '@theme-ui/components';
import { useState, useEffect, React } from 'react';
import TeamCard from '../../components/TeamCard/TeamCard';
import './Teams.css';

/** @jsxImportSource theme-ui */

const quote = 'Silence is golden.'

export default function Teams() {
    const [ teamMember, setTeamMember ] = useState([]);
    useEffect(() => {
        window.scrollTo(0,0);
        const url = 'https://api.airtable.com/v0/appqLAIXpfmBRorop/Core%20Team?fields%5B%5D=name&fields%5B%5D=domain&fields%5B%5D=lead&fields%5B%5D=github&fields%5B%5D=linkedin&fields%5B%5D=twitter&fields%5B%5D=instagram&fields%5B%5D=profp&fields%5B%5D=notes&view=Core+Team';
        fetch(url,{
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_AIRTABLE_API_KEY
            },
        })
        .then((response)=> response.json())
        .then((data)=>{
            setTeamMember(data.records);
            return;
        })
        .catch((err)=>{
            console.log(err);
        });
    },[])

    return (
        <div className='teams'>
            <div className="teams__header" sx={{ color: 'white', px: 5 }}>

                <span
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 6,
                    }}
                >
                    By the students, <br />
                    for the students.
                </span>

                <span
                    sx={{
                        fontSize: 4,
                        mt: 3
                    }}
                >
                    We believe in a world where every young person is <br /> empowered to be the change they want to see around <br />
                    them. At Hack Club, we’re working hard to make it reality.
                </span>
            </div>

            <div className="team__content" sx={{ px: 5, py: 5 }}>
                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'primary' }}>Lead and Co-Lead</span>
                <Grid columns={[1, 1, 2]}>
                    {teamMember.map((member)=>{
                        const lead = member.fields.lead;
                        if(lead === "Lead" || lead === "Co-Lead"){
                            return(
                                <TeamCard
                                    key = { member.id }
                                    name = {member.fields.name}
                                    avatar = { member.fields.profp }
                                    twitter= { member.fields.twitter }
                                    linkedin= { member.fields.linkedin }
                                    github= { member.fields.github }
                                    role = { member.fields.lead }
                                    quote = { member.fields.notes || quote }
                                />
                            );
                        }
                    })}
                </Grid>

                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'orange', mt: 5, display: 'block' }}>Website Development</span>
                
                <Grid columns={[1, 1, 2]}>
                    {teamMember.map((member)=>{
                        const domain = member.fields.domain;
                        if(domain === "Website Development"){
                            return(
                                <TeamCard
                                    key = { member.id }
                                    name = {member.fields.name}
                                    avatar = { member.fields.profp }
                                    twitter= { member.fields.twitter }
                                    linkedin= { member.fields.linkedin }
                                    github= { member.fields.github }
                                    role = { member.fields.domain }
                                    quote = { member.fields.notes || quote }
                                />
                            )
                        }
                    })}
                </Grid>

                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'green', mt: 5, display: 'block' }}>Community Management</span>
                
                <Grid columns={[1, 1, 2]}>
                    {teamMember.map((member)=>{
                        const domain = member.fields.domain;
                        if(domain === "Community Manager"){
                            return(
                                <TeamCard
                                    key = { member.id }
                                    name = {member.fields.name}
                                    avatar = { member.fields.profp }
                                    twitter= { member.fields.twitter }
                                    linkedin= { member.fields.linkedin }
                                    github= { member.fields.github }
                                    role = { member.fields.domain }
                                    quote = { member.fields.notes || quote }
                                />
                            );
                        }
                    })}
                </Grid>

                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'purple', mt: 5, display: 'block' }}>Operations</span>
               
                <Grid columns={[1, 1, 2]}>
                    {teamMember.map(member=>{
                        const domain = member.fields.domain;
                        if(domain === "Operations"){
                            return(
                                <TeamCard
                                    key = { member.id }
                                    name = {member.fields.name}
                                    avatar = { member.fields.profp }
                                    twitter= { member.fields.twitter }
                                    linkedin= { member.fields.linkedin }
                                    github= { member.fields.github }
                                    role = { member.fields.domain }
                                    quote = { member.fields.notes || quote }
                                />
                            )
                        }
                    })}
                </Grid>

                <span sx={{ fontSize: 6, fontWeight: 'bold', color: 'blue', mt: 5, display: 'block' }}>Design</span>
               
                <Grid columns={[1, 1, 2]}>
                    {teamMember.map((member)=>{
                        const domain = member.fields.domain;
                        if(domain === "Design"){
                            return(
                                <TeamCard
                                    key = { member.id }
                                    name = {member.fields.name}
                                    avatar = { member.fields.profp }
                                    twitter= { member.fields.twitter }
                                    linkedin= { member.fields.linkedin }
                                    github= { member.fields.github }
                                    role = { member.fields.domain }
                                    quote = { member.fields.notes || quote }
                                />
                            );
                        }
                    })}

                </Grid>
                <h3 sx={{ fontSize: 4, color: 'primary', mt: 5, textAlign: 'center' }}>Check out our past members ⇒</h3>
            </div>
        </div>
    )
}

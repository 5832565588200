import React from 'react'
import '../Perks/Perks.css'
import musk from '../../images/image 1.png'
import bounty from '../../images/bounty 1.png'
import zoom from '../../images/Zoom.png'
import basket from '../../images/Basket.png'
import mac from '../../images/macintosh.png'
import coke from '../../images/enjoy 1.png'
import stranger from '../../images/stranger_hacks.png'
import jur from '../../images/jurassic_hack.png'

export default function Perks() {
    return(
        <div className="perks">
            <h1>~PERKS~</h1>

            <div className="grid-container">

                <div className="card-semi one">
                    <h2>Free Zoom Pro</h2>
                    <p>A paid Zoom subscription for endless fun and productive meetings without the time limit.</p>
                </div>
                <img loading='lazy' className="zoom" placeholder='perks' src={zoom} alt="perks"/>

                <div className="card-semi two">
                    <h2>A basket of Free Tools</h2>
                    <p>Free subscriptions to Notion Pro, Figma Pro, repl.it and more for increased productivity. And instant access for every member to GitHub Student Developer Pack.</p>
                </div>
                <img loading='lazy' className="basket" placeholder='perks' src={basket}  alt="perks"/>

                <div className="card-semi three">
                    <h2>Workshop Bounty Program</h2>
                    <p>Get paid $200 for submitting curriculum you make for your club for every club to use. Help fund your club, or have fun with your bounties.</p>
                    
                </div>
                <img loading='lazy' className="bounty" placeholder='perks' src={bounty}  alt="perks"/>

                <div className="card-semi four">
                    <h2>Weekly events</h2>
                    <p>From Hack Night to AMAs to weirder events, the Slack community has live events for leaders & members alike every week.</p>
                </div>

            </div>

            

            <img loading='lazy' placeholder='perks' src={musk} className="musk" alt="perks"/>
            <div className="musk-text">
                <h2>AMAs</h2>
                <p>Every month, we call someone we’ve always wanted to talk to—but the entire Hack Club community is invited, to ask questions & chat with the guest. They’re streamed live publicly on YouTube.</p>
                <br/>
                <h2>Past Guests</h2>
                <p><span style={{fontWeight: '600'}}>Elon Musk</span>, CEO Tesla <br/>
                <span style={{fontWeight: '600'}}>Jack Conte</span>, CEO Patreon <br/>
                <span style={{fontWeight: '600'}}>Patrick Collison</span>, CEO Stripe <br/>
                <span style={{fontWeight: '600'}}>Dylan Field</span>, CEO & Co-Founder Figma</p>

            </div>

            <div className="stickers">
                <h3 className="stickers-text">Unparalleled stickers!</h3>
                <img loading='lazy' className="mac" placeholder='perks' src={mac} alt="perks"/>
                <img loading='lazy' className="coke" placeholder='perks' src={coke} alt="perks"/>
                <img loading='lazy' className="stranger" placeholder='perks' src={stranger} alt="perks"/>
                <img loading='lazy' className="jur" placeholder='perks' src={jur} alt="perks"/>
            </div>
        </div>
    )
}
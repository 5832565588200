import React from 'react'
import '../NextSteps/nextsteps.css'
import { Button, Card, Grid } from 'theme-ui';
import application from '../../images/Application.png'
import review from '../../images/Review.png'
import mail from '../../images/Group 40.png'
import { Link } from 'react-router-dom';

export default function NextSteps() {
    return(
        <div className="next">
        <h1 style={{color: '#fff', opacity: 0.75}} >~NEXT STEPS~</h1>
        <h2 style={{color: '#fff'}}>Apply today to <span style={{color: '#fb558e'}}>become a member.</span></h2>

        <p style={{color: '#fff'}}>It’s all-online, free, &amp;takes under an hour. We’ll help from there!</p>

        <Grid width={[256]} className="carddd" gap={5}>
            <Card className="box">
                <img placeholder='nextsteps' loading='lazy' src={application} height='70px' alt="application"/>
                <div style={{color:'black', opacity:'0.7'}}>
                    <h2 style={{fontSize: '2rem', marginTop: 0}}>1. Application</h2>
                    <p>Start by telling us about yourself.</p>
                </div>

            </Card>

            <Card className="box" style={{background: 'linear-gradient(188.49deg, rgba(255, 212, 64, 0.9) 6.5%, rgba(255, 88, 88) 100%)'}}>
                <img placeholder='nextsteps' loading='lazy' src={review} height='70px' alt="Time"/>
                <div style={{color:'black', opacity:'0.7'}}>
                    <h2 style={{fontSize: '2rem', marginTop: 0}}>2. Review</h2>
                    <p>Give us some time, while we review your application.</p>
                </div>

            </Card>

            <Card className="box" style={{background: 'linear-gradient(117.86deg, rgba(251, 85, 142, 0.9) 0%, rgba(111, 49, 183) 100%)'}}>
                <img placeholder='nextsteps' loading='lazy' src={mail} height='70px' alt="Celebrate"/>
                <h2 style={{fontSize: '2rem', marginTop: 0, color: '#fff'}}>3. Mail</h2>
                <p style={{color: '#fff'}}>Celebrate being selected as a member with our mail!</p>

            </Card>
            </Grid>
        
        <Link to='/membership'>
            <Button variant="cta">BECOME A MEMBER</Button>
        </Link>
    </div>
    ); 
}
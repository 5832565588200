import React, {useEffect} from 'react'
import '../CodeOC/CodeOC.css'

import coc_img from '../../images/coc_img.png'
import { Image, Text } from '@theme-ui/components';

export default function CodeOC() {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (

        <div className="codeofconduct">
            <div className="coc_container">
                <Image src={coc_img} alt="Code of conduct Image" />
                <Text className="coc_cont_text" sx={{fontWeight: 'bold', color: 'white'}}>Code of Conduct</Text>
            </div>
            <div className="coc_content">
                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >TL;DR</Text>
                <Text sx={{fontSize: 3}}>
                    <ul>
                        <li>Treat everyone with respect and kindness.</li>
                        <li>Be thoughtful in how you communicate. </li>
                        <li>Don't be destructive or inflammatory. </li>
                        <li>If you encounter an issue, reach out to our Community Managers or KIIT Hack Club team.</li>
                    </ul>
                </Text>

                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Hacker Values</Text>
                <Text sx={{fontSize: 3}}>
                    <p>These are the values to which people in the KIIT Hack Club community should aspire. </p> <br/>
                    <ul>
                        <li>Be friendly and welcoming</li>
                        <li>Be patient</li>
                        <li>Be thoughtful</li>
                        <li>Be respectful</li>
                        <li>Be charitable</li>
                        <li>Avoid destructiove behaviour:
                            <ul>
                                <li>Derailing</li>
                                <li>Unconstructive Criticism </li>
                                <li>Snarking(pithy, unproductive, sniping comments)</li>
                                <li>Discussing potentially offensive or sensitive issues</li>
                                <li>Microaggressions</li>
                            </ul>
                        </li>
                    </ul>
                    <br/>

                    <p>People are complicated. You should expect to be misunderstood and to misunderstand others; when this inevitably occurs, resist the urge to be defensive or assign blame. Try not to take offense where no offense was intended. Give people the benefit of the doubt. Even if the intent was to provoke, do not rise to it. It is the responsibility of all parties to de-escalate conflict when it arises.</p>
                </Text>

                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Unwelcome Behaviour</Text>
                <Text sx={{fontSize: 3}}>

                    <p>These actions are explicitly forbidden in Hack Club spaces:</p> <br/>
                    <ul>
                    <li>Expressing or provoking:
                        <ul>
                        <li>insulting, demeaning, hateful, or threatening remarks;</li>
                        <li>discrimination based on age, nationality, race, (dis)ability, gender (identity or expression),  sexuality, religion, or similar personal characteristic;</li>
                        <li>bullying or systematic harassment;</li>
                        <li>unwelcome sexual advances, including sexually explicit content.</li>
                        </ul>

                        <li>Excessive advertisement for unnecessary or non-beneficial commercial products and services.</li>
                        <li>Posting spam-like content that disrupts the environment of the community.</li>
                    </li>

                    </ul>
                </Text>

                
                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Moderation & Enforcement</Text>
                <Text sx={{fontSize: 3}}> <br/>

                <p>Most importantly, use common sense and understand that speech and actions have consequences, and unacceptable behavior will not be tolerated; please listen to what our moderators say. You should act in the spirit of the "Hacker values". Our moderation team will be keeping an eye on things in the server and will step in if they feel someone is conducting themselves in a way that is explicitly forbidden, they  will be warned and asked to stop, and their messages may be removed by community moderators. Repeated offenses may result in a temporary or permanent ban from the community.</p>
                </Text>
                                
                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Working Groups</Text>
                <Text sx={{fontSize: 3}}> <br/>

                <p>The Working Group is responsible for handling conduct-related issues. Their mission is to de-escalate conflicts and try to resolve issues to the satisfaction of all parties. For all projects related to and/or maintained by KIIT Hack Club, the Working Group is made up of the KIIT Hack Club team. The specific team member(s) handling each violation depend on the location and nature of the issue.</p>
                </Text>

                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Reporting Issues</Text>
                <Text sx={{fontSize: 3}}> <br/>

                <p>If you encounter a conduct-related issue, you should report it to the Working Group using the process described below. Do not post about the issue publicly or try to rally sentiment against a particular individual or group.
                <ul>
                <li>Reach out to our Community Managers or KIIT Hack Club team
                <ul>
                <li>Direct message the Working Group.</li>
                <li>Reports are confidential within the Working Group.</li>
                <li>Should you choose to remain anonymous then the Working Group cannot notify you of the outcome of your report.</li>
                <li>You may contact a member of the group directly if you do not feel comfortable contacting the group as a whole. That member will then raise the issue with the Working Group as a whole, preserving the privacy of the reporter (if desired).</li>
                <li>If your report concerns a member of the Working Group they will be recused from Working Group discussions of the report.</li>
                <li>The Working Group will strive to handle reports with discretion and sensitivity, to protect the privacy of the involved parties, and to avoid conflicts of interest.</li></ul></li>
                <li>
                You should receive a response within 48 hours (likely sooner)</li>
                <li>The Working Group will meet to review the incident and determine what happened.
                    <ul>
                <li>With the permission of person reporting the incident, the Working Group may reach out to other community members for more context.</li>
                <li>The Working Group will reach a decision as to how to act. These may include:
                    <ul>
                <li>Nothing.</li>
                <li>A request for a private or public apology.</li>
                <li>A private or public warning.</li>
                <li>An imposed vacation (for instance, asking someone to abstain for a week from Discord or an impending event).</li>

                <li>A permanent or temporary ban from some or all Hack Club spaces.</li></ul></li>

                <li>The Working Group will reach out to the original reporter to let them know the decision.</li>
                <li>Appeals to the decision may be made to the Working Group, or to any of its members directly.</li>
                </ul>
                </li>
                </ul>

                <br/>

                Note that the goal of the Code of Conduct and the Working Group is to resolve conflicts in the most harmonious way possible. We hope that in most cases issues may be resolved through polite discussion and mutual agreement. Bannings and other forceful measures are to be employed only as a last resort.</p>
                </Text>

                <Text sx={{fontWeight: 'bold', color: '#EC3750', fontSize: 5}} >Acknowledgements</Text>
                <Text sx={{fontSize: 3}}> <br/>

                <p>This was adapted from Hack Club’s Code of Conduct. It is to be noted that many parts of Hack Club’s Code of Conduct are adopted from the Code of Conduct documents of the Go, Django, FreeBSD, and Rust projects.</p>
                </Text>
            </div>

        </div>
    )
}

import React from 'react';
import '../Footer/footer.css';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

/** @jsxImportSource theme-ui */

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer_navs">
                <div>
                    <h2>KIIT Hack Club</h2>
                    <Link to="/team"><h3>Team</h3></Link>
                    <a href="https://hackclub.com/philosophy/"><h3>Philosophy</h3></a>
                    <Link to ="/press"><h3>Press</h3></Link>
                </div>
                <div>
                    <img loading='lazy' className="logo" src={logo} alt="Hackclub Logo" />
                </div>
                <div style={{ textAlign: 'right' }} >
                    <h2>Resources</h2>
                    <Link to="/coc"><h3>Code of Conduct</h3></Link>
                    <Link to="/comingSoon"><h3>Events</h3></Link>
                    <Link to="/hackspace"><h3>Hackspace</h3></Link>
                    <Link to="/comingSoon"><h3>Hackathons</h3></Link>
                </div>
            </div>
            <div className="footer__social">
                <div className="footer__socialLeft">
                    <a target="_blank" rel="noreferrer" href='https://youtube.com/channel/UC5lpOLZYTRrctY8yjJXSwIg'><i class="fab fa-youtube"></i></a>
                    <a target="_blank" rel="noreferrer" href='https://twitter.com/kiithackclub/'><i class="fab fa-twitter"></i></a>
                </div>
                <div className="footer__socialCenter">
                    <a target="_blank" rel="noreferrer" href='https://instagram.com/kiithackclub/'><i class="fab fa-instagram"></i></a>
                    <a target="_blank" rel="noreferrer" href='https://github.com/kiithackclub'><i class="fab fa-github"></i></a>
                </div>
                <div className="footer__socialRight">
                    <Link to='/podcasts'><i class="fab fa-spotify"></i></Link>
                    <a target="_blank" rel="noreferrer" href='https://linkedin.com/company/kiithackclub/'><i class="fab fa-linkedin"></i></a>
                </div>
            </div>

            <div className="footer_at" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1%' }}>@kiithackclub</div>
        </div>
    );
}

import React from 'react'
import { Box, Button, Grid, Image, Text, Container } from '@theme-ui/components';

import '../../pages/Podcasts/Podcasts.css'

import podcasts from '../../images/podcasts.png'


export default function Podcasts() {
    return (
        <div className="podcasts">
            <div className="press-top-banner">
                <img src={podcasts} className="press-img" />
                <Text className="press-banner-text" sx={{ fontWeight: 'bold', color: 'white', fontSize: 7 }} >Podcasts</Text>
            </div>

            <div className="row">
                <div className="angular angular1">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 6 }} className="hk" >Hack Knowledge</Text>
                    <Text sx={{fontSize: 3}}>Open Podcast </Text>
                </div>
                <div className="angular angular2">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 6 }} className="hk" >Hack Knowledge</Text>
                    <Text sx={{fontSize: 3}} >Open Podcast </Text>
                </div>
            </div>

            <div className="row">
                <div className="angular angular3">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 6 }} className="hk" >Hack Knowledge</Text>
                    <Text sx={{fontSize: 3}}>Open Podcast </Text>
                </div>
                <div className="angular angular4">
                    <Text sx={{ fontWeight: 'bold', color: 'white', fontSize: 6 }} className="hk" >Hack Knowledge</Text>
                    <Text sx={{fontSize: 3}}>Open Podcast </Text>
                </div>
            </div>

        </div>
    )
}

import './App.css';
import Landing from './components/Landing/Landing';
import Navbar from './components/Navbar/Navbar';
import Philosophy from './components/Philosophy/Philosophy'
import Perks from './components/Perks/Perks'
import NextSteps from './components/NextSteps/Nextsteps'
import Footer from './components/Footer/Footer'
import '@hackclub/theme/fonts/reg-bold.css';
import Teams from './pages/Teams/Teams';
import Member from './pages/Member/Member';
import CodeOC from './pages/CodeOC/CodeOC'
import MemberCore from './pages/MemberCore/MemberCore'
import Press from './pages/Press/Press'
import Podcasts from './pages/Podcasts/Podcasts'

import {
  Switch,
  Route,
} from "react-router-dom";
import Upcoming from './pages/Upcoming/Upcoming';
import HackSpace from './pages/HackSpace/HackSpace';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route path='/team'>
          <Teams />
        </Route>
        {/* <Route path='/membership'>
          <Member />
        </Route> */}
        <Route path="/press">
          <Press />
        </Route>

        <Route path="/podcasts">
          <Podcasts />
        </Route>
        <Route path='/membership'>
          <MemberCore />
        </Route>
        <Route path='/comingSoon'>
          <Upcoming />
        </Route>
        <Route path='/hackspace'>
            <HackSpace />
        </Route>
        <Route exact path='/'>
          <Landing />
          <Philosophy />
          <Perks />
          <NextSteps />
        </Route>
        <Route path="/coc">
          <CodeOC />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;

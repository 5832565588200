import React, { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect/dist/core';
import "./Upcoming.css";

export default function Upcoming() {

    const upcomingEl = useRef(null);
    const messageEl = useRef(null);

    useEffect(() => {
        let colors = ["#338eda", "#5bc0de", "#ff8c37", "#33d6a6", "#a633d6"];
        let i = 0;

        function change() {


            upcomingEl.current.style.backgroundColor = colors[i];
            i++;
            
            if (i > colors.length - 1) {
                i = 0;
            } 

        }
        var timer = setInterval(change, 2000);
        document.onclick = function(event){
            clearInterval(timer);
        }
        new Typewriter('#typewriter', {
            strings: ["Getting things ready...", "Running field tests...", "Tweaking the system...", "Hacking the machine..."],
            autoStart: true,
            loop: true
        });
                
    }, [])

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (
        <div ref={upcomingEl} className="upcoming">
            <div className="upcoming__content">
                <img src="https://assets.hackclub.com/flag-standalone.svg" height="100vh" alt="hackclub" />
                <h2>KIIT Hack Club</h2>
                <p ref={messageEl} id="typewriter" className="upcoming__message">Hacking the machine...</p>
                <p className="upcoming__comingSoon">We will be up soon, keep an eye on this space!   </p>
            </div>
        </div>
    )
}
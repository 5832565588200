import React, { useEffect, useRef } from 'react';
import "./Landing.css";
import vec from "../../images/Art_lowerLeft.png"
import vec1 from "../../images/Art_lowerRight.png"

export default function Landing() {

    const landingEl = useRef(null);

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (
        <div>
        <div ref={landingEl} className="landing">
            <div className="landing__content">
                <span className="s1">WE'RE</span>
                <span className="s2">AT OUR BEST</span>
                <span className="s3">WHEN WE'RE</span>
                <div className="paral">
                    <span className="paraltext">MAKING.</span>
                </div>
            </div>

            <img loading='lazy' src={vec} style={{position: 'absolute', bottom: 0, left: 0, maxWidth: '25vmax'}} alt="abstract png"/>
            <img loading='lazy' src={vec1} style={{position: 'absolute', bottom: 0, right: 0, maxWidth: '25vmax'}} alt="abstract png"/>

            <h2 style={{position: 'absolute', color: 'black', bottom: '1em', left: '1em'}}>By the Students,<br/>For the Students.</h2>
            <h2 style={{position: 'absolute', color: 'black', bottom: '1em', right: '1em'}}>Coding is a<br/>
            <div className="paral1">
                <span className="paraltext1">Superpower.</span>
            </div>
            </h2>

        </div>
        </div>
    )
}

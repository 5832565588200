import React from 'react';
import './TeamCard.css';

import { ReactComponent as GithubI8 } from '../../images/icons8-github.svg';
import { ReactComponent as LinkedinI8 } from '../../images/icons8-linkedin.svg';
import { ReactComponent as TwitterI8 } from '../../images/icons8-twitter.svg';
import { Card, Image, Text } from '@theme-ui/components';


/** @jsxImportSource theme-ui */

export default function TeamCard(props) {
    return (
        <Card className="teamCard" variant="interactive" sx={{ maxWidth: 700, maxHeight: 300, backgroundColor:'white'}}>
            <div className="teamCard__left">
                <Image decoding src={props.avatar} alt="Abhishek Raj" />

                <div className="teamCard__social" sx={{ my: 3}}>
                    <a href={props.twitter} target="_blank" rel="noopener noreferrer"><TwitterI8/></a>
                    <a href={props.linkedin} target="_blank" rel="noopener noreferrer"><LinkedinI8/></a>
                    <a href={props.github} target="_blank" rel="noopener noreferrer"><GithubI8/></a>
                </div>
            </div>

            <div className="teamCard__right" sx={{ card: 'primary', mx: 3 }}>
                <Text sx={{ fontSize: 4, fontWeight: 'bold' , lineHeight: 1.2}}>{props.name}</Text>
                <Text sx={{ fontSize: 3, fontWeight: 'bold', color: '#24B5A5', lineHeight: 1.7}}>{props.role}</Text>
                <Text sx={{ fontSize: 2, lineHeight: 1.2 }}>{props.quote}</Text>
            </div>
        </Card>
    )
}